import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
import Button from '@emcasa/ui-dom/components/Button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <p>{`A simple `}<inlineCode parentName="p">{`Text`}</inlineCode>{`. All text should be wrapped in this component for better compatibility with platforms that don't support text outside of tags, such as React Native.`}</p>
    <p><inlineCode parentName="p">{`Text`}</inlineCode>{` accepts any component or strings as children.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Text>This is a paragraph.</Text>\n<Text inline>\n  This is an inline text. A span element is rendered instead.\n</Text>\n<Text fontWeight=\"bold\" color=\"pink\">\n  Styled text\n</Text>\n<Text>\n  There\'s a <Button>button</Button> inside this text!\n</Text>\n<Text fontSize=\"xlarge\">This text is very large.</Text>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Text,
      View,
      Button
    }} mdxType="Playground">
  <Text mdxType="Text">This is a paragraph.</Text>
  <Text inline mdxType="Text">This is an inline text. A span element is rendered instead.</Text>
  <Text fontWeight="bold" color="pink" mdxType="Text">Styled text</Text>
  <Text mdxType="Text">There's a <Button mdxType="Button">button</Button> inside this text!</Text>
  <Text fontSize="xlarge" mdxType="Text">This text is very large.</Text>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      